'use client';

import '#styles/globals.css';

import { Button } from '@zealy/design-system';
import { Syne as DisplayHeading } from 'next/font/google';
import Link from 'next/link';
import React from 'react';
import { cx } from 'class-variance-authority';

const fontHeading = DisplayHeading({
  subsets: ['latin'],

  variable: '--font-heading',
});

export default function GlobalError({ error, reset }: { error: Error; reset: () => void }) {
  return (
    <html lang="en" className="dark">
      <body className={cx('min-h-screen bg-primary font-sans antialiased', fontHeading.variable)}>
        <main className="px-150 md:px-300 mx-auto h-full max-w-[1400px]">
          <div className="h-4/5 grid justify-items-start content-center max-w-[546px]">
            <h4 className="section-heading text-brand-primary mb-200">
              An unexpected error occurred
            </h4>
            <h1 className="heading-1 text-primary mb-300">Oops! Something went wrong</h1>
            <p className="text-secondary body-lg mb-200">
              We&apos;re experiencing some technical difficulties, but our team is on it. While we
              work on fixing the issue, take a break with a cup of coffee. Contact us if you need
              assistance.
            </p>
            <div className="flex items-center mt-500 gap-200">
              <Button as={Link} href="/">
                Return home
              </Button>
              {reset && <Button onClick={reset}>Try again</Button>}
            </div>
          </div>
        </main>
      </body>
    </html>
  );
}
